.closure {
	flex-wrap: nowrap;

	&__time {
		padding-top: 2px;
		white-space: nowrap;
	}

	&__link {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		float: right;

		&:hover {
			color: var(--mantine-primary-color-4);
		}
	}

	&__link__icon {
		fill: var(--mantine-primary-color-6);
		height: 22px;
	}
}
