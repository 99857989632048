.vehicle-stats-table {
	th {
		background-color: var(--mantine-color-white);
		border-bottom: 1px solid var(--mantine-color-gray-5) !important;
	}

	tr {
		height: 40px !important;

		:first-child {
			text-align: left;
		}
	}

	td {
		padding: 0;
	}
}
