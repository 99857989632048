@import '../../styles/global-variables.module';

.container {
	margin: 24px;

	@media (min-width: $screen-lg) {
		margin: 24px 100px;
	}

	@media (max-width: $screen-lg) {
		margin: 24px 0;
	}
}

.header {
	@media (max-width: $screen-lg) {
		margin: 0 12px;
	}
}

.modal {
	height: 100%;
}
