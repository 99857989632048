@import '../../../../styles/global-variables.module';

.vehicle-accumulated {
	&__listing {
		display: flex;
		flex-wrap: wrap;
		margin-top: 24px;
	}
}

@media (max-width: $screen-lg) {
	.vehicle-accumulated {
		&__listing {
			flex-wrap: nowrap;
			justify-content: space-between;
		}
	}
}
