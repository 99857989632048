@import '../../styles/global-variables.module';

.heading {
	margin: 24px;

	@media (min-width: $screen-lg) {
		margin: 24px 100px;
	}
}

.container {
	margin: 24px;

	@media (min-width: $screen-lg) {
		margin: 48px 100px;
	}
}

.planned-closures-button {
	align-self: start;
}
