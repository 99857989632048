@import '../../../styles/global-variables.module';

.cluster-box {
	&__outer {
		align-items: center;
		background: transparent;
		border: 2px solid $color-bvg;
		border-radius: 50%;
		display: flex;
		height: 38px;
		justify-content: center;
		width: 38px;
	}

	&__inner {
		background: $color-bvg;
		border-radius: 50%;
		font-size: 13px;
		height: 23px;
		line-height: 23px;
		text-align: center;
		width: 23px;
	}

	&__top-right-icons {
		display: flex;
		flex-wrap: nowrap;
		position: absolute;
		left: 25px;
		top: -8px;
	}

	&__bottom-right-icons {
		display: flex;
		flex-wrap: nowrap;
		position: absolute;
		left: 25px;
		top: 28px;
	}

	&__bottom-left-icons {
		display: flex;
		flex-wrap: nowrap;
		position: absolute;
		left: -4px;
		top: 28px;
	}
}
