@import '../../styles/global-variables.module';

// desktop related variables
$details-width: 116vw;
$details-max-width: 800px;
$animation-side-menu-open-duration: 300ms;
$animation-side-menu-close-duration: 300ms;

// mobile related variables
$animation-map-shrink-duration: 300ms;
$animation-map-grow-duration: 300ms;
$mobile-details-max-height: calc(100vh - 72px);
$mobile-details-min-height: 25vh;

// make variable available in javascript
:export {
	export_station_details_animation_open_duration: $animation-side-menu-open-duration;
}

.layout {
	display: flex;
	flex-basis: 100%;
	flex-wrap: wrap;

	.map-wrapper {
		flex-grow: 1;
		height: $mobile-details-max-height;
		width: 100%;
		z-index: 0;

		&--transition {
			transition: 300ms;
		}

		.map {
			height: 100%;

			.other-micromobility-area-cluster {
				background-color: #4a4a4a;
				color: #fff;
				border-radius: 50%;
				text-align: center;
				line-height: 28px;
			}
		}

		&--small {
			height: $mobile-details-min-height;
		}

		&--shrink {
			animation: shrink-map $animation-side-menu-open-duration ease-out forwards;

			@keyframes shrink-map {
				from {
					height: $mobile-details-max-height;
				}

				to {
					height: $mobile-details-min-height;
				}
			}
		}

		&--grow {
			animation: grow-map $animation-map-grow-duration ease-out forwards;

			@keyframes grow-map {
				from {
					height: $mobile-details-min-height;
				}

				to {
					height: $mobile-details-max-height;
				}
			}
		}
	}

	.details {
		background-color: #fff !important;

		border-radius: 15px 15px 0 0;
		box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.15);
		display: none;
		height: 66vh;
		max-width: 100%;
		overflow: hidden;
		padding: 0 16px;
		transition: border-radius 200ms;
		width: 100%;
		z-index: 1;

		&__close-button {
			display: block;
			float: right;
			top: 8px;
			padding: 0;
			margin-left: 14px;
			font-size: 24px !important;
			color: #c4c4c4 !important;
		}

		&--close {
			display: none;
		}

		&--open {
			display: block;
		}

		&--nomap {
			border-radius: 0;
			height: calc(100vh - 72px);
		}
	}
}

// devices with big screens
@media (min-width: $screen-lg) {
	.layout {
		flex-wrap: nowrap;

		.map-wrapper {
			// 100 - header (incl. border)
			height: calc(100vh - 81px) !important;
		}

		.details {
			border-left: solid 1px #c4c4c4;
			border-radius: 0;
			box-shadow: none;
			height: initial;
			padding: 0;
			position: initial;
			width: 0;

			&--close {
				animation: close-to-right $animation-side-menu-close-duration ease-out forwards;

				* {
					animation: close-to-right-inside $animation-side-menu-close-duration;
					animation-fill-mode: forwards;
					animation-timing-function: cubic-bezier(1, -0.17, 1, 0.48);
				}

				@keyframes close-to-right {
					from {
						flex-basis: $details-width;
						max-width: $details-max-width;
						padding: 0 56px;
					}

					to {
						flex-basis: 0;
						padding: 0;
						width: 0;
					}
				}

				@keyframes close-to-right-inside {
					from {
						flex-basis: $details-width;
						max-width: $details-max-width;
						opacity: 0;
						padding: 0;
					}

					to {
						flex-basis: 0;
						opacity: 0;
						padding: 0;
					}
				}
			}

			&--open {
				animation: open-to-left $animation-side-menu-open-duration ease-out forwards;

				@keyframes open-to-left {
					from {
						max-width: 0;
						min-width: 0;
						padding: 0;
					}

					to {
						flex-basis: $details-width;
						max-width: $details-max-width;
						padding: 0 40px;
					}
				}
			}

			&__close-button {
				font-size: 1.98rem !important;
				top: 24px;
			}
		}
	}
}
