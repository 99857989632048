@import '../../styles/global-variables.module';

$color-primary: var(--mantine-primary-color-6);

.station-information {
	background-color: var(--mantine-primary-white-1);
	width: 100%;

	&__fixed-area {
		padding-top: 32px;
		padding-bottom: 16px;
	}
}

// changing the view for devices with smaller screens
@media (max-width: 1200px) {
	.station-information {
		::-webkit-scrollbar {
			width: 4px;
		}

		::-webkit-scrollbar-thumb {
			background-color: #a8a8a8;
			border-radius: 10px;
		}

		&__fixed-area {
			padding-top: 20px;
		}
	}
}
