@import '../../../styles/global-variables.module';

$x-side-margin-desktop: 60px;
$x-side-margin-mobile: 32px;

.filter-button {
	left: $x-side-margin-mobile;
	bottom: 120px;
	position: absolute;
	z-index: 1000;
}

.settings-button {
	position: absolute;
	top: 100px;
	left: $x-side-margin-mobile;
	z-index: 1000;
}

.add-station-button {
	left: $x-side-margin-mobile;
	bottom: 48px;
	position: absolute;
	z-index: 1000;
}

// devices with big screens
@media (min-width: $screen-lg) {
	.settings-button {
		top: unset;
		left: unset;
		bottom: 48px;
		right: $x-side-margin-desktop;
	}

	.filter-button {
		left: $x-side-margin-desktop;
		bottom: unset;
		top: 120px;
	}

	.add-station-button {
		left: $x-side-margin-desktop;
	}
}
