@import './global-variables.module';

/* first for Internet Explorer < 9 */
@font-face {
	font-family: Transit;
	src: url('/fonts/BVG_Transit_Web/Regular/TransitWeb.eot');
}
/* then for WOFF-capable browsers */
@font-face {
	font-family: Transit;
	src: url('/fonts/BVG_Transit_Web/Regular/TransitWeb.woff') format('woff');
}

@font-face {
	font-family: TransitPict;
	src: url('/fonts/BVG_Transit_Web/Pict/Transit-Pict.eot');
}
/* then for WOFF-capable browsers */
@font-face {
	font-family: TransitPict;
	src: url('/fonts/BVG_Transit_Web/Pict/Transit-Pict.woff') format('woff');
}

body {
	font-family: $bvg-font-family;
	font-weight: $bvg-font-size-base;
	margin: 0;

	&:has(.hide-global-scroll-bar) {
		overflow: hidden;
	}
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 24px;
}

h3 {
	font-size: 20px;
}

span {
	font-family: $bvg-font-family;
}

// changes to leaflet
.leaflet-popup-content-wrapper {
	background-color: var(--mantine-color-body) !important;
	border-radius: 4px !important;
	border: solid 2px var(--mantine-color-default-border) !important;
	color: var(--mantine-color-text) !important;
	min-width: 30px;
	text-align: center !important;
}

.leaflet-popup-tip {
	background-color: var(--mantine-color-body) !important;
}

.leaflet-popup-close-button {
	display: none !important;
}

.leaflet-popup-content {
	margin: 8px !important;
	width: auto !important;
}

.leaflet-control-attribution {
	background-color: rgba(255, 255, 255, 0.3) !important;
	// mobile phone
	@media (max-width: $screen-xs) {
		font-size: 8px !important;
		text-align: right;
	}
	// tablets / desktops
	@media (min-width: $screen-xs-max) {
		font-size: 10px !important;
	}
}
