@import '../../styles/global-variables.module';

.root {
	padding: 80px 24px;
	height: 100%;
}

.description {
	max-width: 600px;
	margin: auto;
	margin-top: var(--mantine-spacing-xl);
	margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
}

.title {
	text-align: center;
	font-weight: bold;
	font-size: var(--mantine-h1-font-size);
}
