.vehicle-accumulated-list {
	width: 100%;

	.item-wrapper {
		width: 100%;
	}

	.item-label {
		width: 100%;
	}

	.item {
		border-bottom: 1px solid var(--mantine-color-gray-2);
	}
}
