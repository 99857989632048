.station-closures-modal {
	padding: 20px 24px;

	&__timeline {
		&__time {
			line-height: 120%;

			span {
				font-style: italic;
			}
		}

		&__comment {
			margin-top: 8px;
			color: var(--mantine-color-dimmed);
			line-height: 120%;
			padding-right: 16px;
			margin-bottom: 8px;
		}
	}
}
