@import '../../styles/global-variables.module';

.container {
	margin: 24px;

	@media (min-width: $screen-lg) {
		margin: 24px 100px 48px;
	}
}

.msp-logo {
	max-height: 20px;
}
