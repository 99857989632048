@import '../../styles/global-variables.module';
@import '../../styles/box-shadow';

.header {
	@include box-shadow(2);
	padding: 0 24px !important;

	@media (min-width: $screen-lg) {
		@include box-shadow(0);
		border-bottom: 1px solid #c4c4c4 !important;
		padding: 0 100px !important;
	}
}
