@import '../../styles/global-variables.module';

.header * {
	background-color: var(--mantine-color-gray-1);

	svg {
		width: 20px;
		height: 20px;
	}
}

.pagination {
	div {
		// style for group component
		flex-wrap: nowrap;
		justify-content: center;

		@media (min-width: $screen-lg) {
			justify-content: flex-end;
		}
	}
}
