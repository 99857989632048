.badge {
	background: #ba0000;
	border: 0.5px solid #000;
	border-radius: 50%;
	color: #fff;
	font-size: 10px;
	font-weight: 500;
	height: 18px;
	left: 24px;
	min-width: 18px;
	padding: 0;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	width: 18px;

	&__badgeBackground {
		background: transparent;
	}

	&__inactive {
		display: none;
	}
}
