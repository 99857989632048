.icon {
	height: 38px;
	width: 38px;

	filter: drop-shadow(1.5px 1px 0.5px #2e2e2e);
}

.popup {
	padding: 0;
	white-space: nowrap;

	.title {
		font-weight: bold;
	}
}
