@import '../../../styles/global-variables.module';

.container {
	margin: 24px;

	@media (min-width: $screen-xl) {
		margin: 48px 100px;
	}

	@media (min-height: $screen-xl) {
		height: 100%;
	}
}

.footer {
	z-index: 1000;
	position: sticky;
	bottom: 0;
	margin: 0 -16px;
	border-top: 1px solid #c4c4c4;

	.buttons {
		padding: 24px;
		background-color: #fff;

		@media (min-width: $screen-xl) {
			padding: 24px 114px 24px 0;
		}
	}
}
