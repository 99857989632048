@import '../../styles/global-variables.module';
@import '../../styles/box-shadow';

.navbar {
	&__row {
		height: 72px;
	}

	&__logo {
		align-items: center;
		display: flex;

		&__image {
			height: 40px;
		}
	}

	&__link {
		color: var(--mantine-color-text);
		text-decoration: none;

		:hover {
			color: var(--mantine-color-text);
			text-decoration: underline;
		}

		&__active {
			color: var(--mantine-color-anchor);
		}
	}

	&__burgermenu__item {
		height: 50px;
	}

	@media (min-width: $screen-lg) {
		&__row {
			height: 80px;
		}

		&__logo {
			&__image {
				height: 50px;
			}
		}
	}
}
