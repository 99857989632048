// $screen-xs and $screen-xs-min is not used in Grid
// smallest break point is $screen-md

// Extra extra small screen / phone
$screen-xxs: 320px;
$screen-xxs-min: $screen-xxs;

// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);

// fonts
$bvg-font-family:
	Transit,
	-apple-system,
	BlinkMacSystemFont,
	'Segoe UI',
	Roboto,
	'Helvetica Neue',
	Arial,
	'Noto Sans',
	sans-serif,
	'Apple Color Emoji',
	'Segoe UI Emoji',
	'Segoe UI Symbol',
	'Noto Color Emoji';
$bvg-font-size-base: 1rem;

// colors
$color-bvg: #f0d722;

// make variables available in javascript
:export {
	/* stylelint-disable property-no-unknown */
	// breakpoints
	breakpoint-screen-lg: $screen-lg;
	/* stylelint-enable */
}
