@import '../../styles/global-variables.module';

.description-length-indicator {
	align-items: flex-end;
	place-content: flex-end;
}

.disabled {
	color: var(--mantine-color-gray-4);
}
