@import '../../../styles/global-variables.module';

.heading {
	margin: 24px;

	@media (min-width: $screen-lg) {
		margin: 24px 100px;
	}
}

.container {
	padding: 24px;
	max-width: var(--mantine-breakpoint-xl);

	@media (min-width: $screen-lg) {
		padding: 48px 100px;
	}
}
