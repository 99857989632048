@import '../../../styles/global-variables.module';

.container {
	margin: 24px;

	@media (min-width: $screen-lg) {
		margin: 48px 100px;
	}

	.link {
		text-decoration: none;
	}
}
