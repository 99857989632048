@import '../../styles/global-variables.module';

.container {
	margin: 24px;

	@media (min-width: $screen-lg) {
		margin: 24px 100px 48px;
	}
}

.version {
	position: absolute;
	left: 0;
	bottom: 0;
	margin: 0 0 8px 8px;
}
