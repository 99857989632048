@import '../../../styles/global-variables.module';

.icon {
	height: 38px;
	width: 38px;

	&__top-right-icons {
		display: flex;
		flex-wrap: nowrap;
		position: absolute;
		left: 25px;
		top: -4px;
	}

	&__bottom-right-icons {
		display: flex;
		flex-wrap: nowrap;
		position: absolute;
		left: 25px;
		top: 26px;
	}

	&__bottom-left-icons {
		display: flex;
		flex-wrap: nowrap;
		position: absolute;
		left: -4px;
		top: 26px;
	}
}

.divider {
	background-color: var(--mantine-color-gray-8);
	margin: 6px 0;
}

.popup {
	padding: 0;
	white-space: nowrap;

	.title {
		font-weight: bold;
	}
}
